import React from 'react'
import { Button, Container, FormGroup, Input } from 'reactstrap'
import qrand from './utils/qrand'

const maxQuants = 65535

const App = () => {
  const [ loading, setLoading ] = React.useState<boolean>(false)
  const [ done, setDone ] = React.useState<boolean>(false)
  const [ words, setWords ] = React.useState<string[]>([])
  const [ depth, setDepth ] = React.useState<number>(1)
  const [ scaleFactor, setScaleFactor ] = React.useState<number>(1)

  const link = React.createRef<HTMLAnchorElement>()

  const getHex = () => new Promise((resolve) => {
    qrand.getRandomHexOctets(depth, (err: any, octets: string[]) => {
      resolve(octets)
    })
  })

  const getWords = async (hexes: string) => {
    const response = await fetch(`https://api.surfomancy.com/${hexes}`)
    const words = await response.json()
    
    return words
  }
  
  const start = async () => {
    setDone(false)
    setLoading(true)
    setWords([])

    let hexes = await getHex() as number[]    
    let hexesList = hexes.map((index) => Math.floor(index * scaleFactor)).join(',')

    let words = await getWords(hexesList)

    setWords(words)
  }

  React.useEffect(() => {
    const getScaleFactor = async () => {
      const response = await fetch('https://api.surfomancy.com/')
      const count = await response.json()

      setScaleFactor(parseInt(count, 10) / maxQuants)
    }

    getScaleFactor()
  }, [])

  React.useEffect(() => {
    if (words.length === depth) {
      setDone(true)
      setLoading(false)
    }
  }, [ words, depth ])
  
  return (
    <div className="App">
      <div className="bg-primary text-white">
        <Container className="pt-5 pb-5">
          <h1>Surfomancy &mdash; blindly surf the world wide web</h1>
          <h2>Set the threshold and go</h2>
        </Container>
      </div>
      <Container className="pt-5">
        <p>Random words are generated via <a href="http://qrng.anu.edu.au/" target="_blank" rel="noopener noreferrer">QRNG</a> when using this tool, and then those words are pushed through DuckDuckGo. Clicking the Go button opens the first DuckDuckGo result (like Google's "Feeling Lucky" feature). Move the slider below to set query length. <strong>Please be aware that the results below might be dangerous.</strong></p>
        <ul>
          <li>Do not give any site permission to log in using one of your social media accounts. That is how you get your Facebook account stolen.</li>
          <li>Some results might be NSFW. I don't recommend using this tool in public, or around people that will judge you.</li>
          <li>If you happen to find anything that looks illegal, <a href="https://www.usa.gov/online-safety" target="_blank" rel="noopener noreferrer">usa.gov</a> has resources on where to go to make a report.</li>
          <li>Take caution opening any downloaded files. You should probably run any generated files through a virus scanner before opening.</li>
        </ul>

        <FormGroup label="Depth" className="mt-5">
          <Input
            type="range"
            value={depth}
            min={1}
            max={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDepth(parseInt(e.target.value, 10))
            }}
          />
        </FormGroup>

        <div>
          <Button className="mr-4" onClick={start} disabled={loading}>Start</Button>
          {loading ? (
            <i className="fas fa-spinner fa-spin"></i>
          ) : null}
          {done && !loading ? (
            <a
              href={`https://duckduckgo.com/?q=!ducky+${words.join('+')}+-dictionary+-definition+-wiki+-java+-medical+-word`}
              target="_blank"
              ref={link}
              rel="noopener noreferrer"
              className="btn btn-primary"
              title="Go"
            >Go</a>
          ) : null}
        </div>
      </Container>
    </div>
  );
}

export default App;
